body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-hover-grow:hover {
  transform: scale(1.05); /* 5% larger */
  transition: transform 0.25s ease;
}

button:hover {
  background-color: grey;
}

.delete-btn {
  border: 1px solid grey;
  background-color: #fff; /* add this line */
  border-radius: 50%;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  overflow: hidden;
  cursor: pointer; /* change cursor to pointer on hover */
  transition: background-color 0.3s ease; /* add this line */
}

.delete-btn:hover {
  background-color: #e24646; /* light red */
}

.card-img-top {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.custom-card {
  display: flex;
  align-items: center; /* Vertically aligns flex items */
  border: 0.5px solid #d3d3d3;
  border-radius: 5px;
}

@keyframes redGlow {
  0% { box-shadow: 0 0 5px rgba(255, 0, 0, 0.5); }
  100% { box-shadow: 0 0 20px rgba(255, 0, 0, 0.5); }
}

.errorGlow {
  animation: redGlow 2s ease-out;
  border-color: red;
}

@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

/* Hide scrollbar but allow scrolling */
html, body {
  overflow: auto;
}

/* For webkit browsers like Chrome/Safari */
html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none;
}

/* For IE and Edge */
html, body {
  -ms-overflow-style: none;
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.5; 
  }
  25%, 75% {
    opacity: 0.75; 
  }
  50% {
    opacity: 1; 
  }
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 25px;
  height: 25px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px; 
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.qa-pair:hover .hover-button {
  opacity: 1; 
}

.delete-btn {
  width: 0;
  height: 30px;
  background-color: red;
  color: white;
  text-align: center;
  line-height: 30px;
  transition: width 0.5s ease;
  cursor: pointer;
  overflow: hidden;
}

.delete-btn:hover {
  background-color: darkred;
}

.show-delete {
  width: 30px;
}

.btn-launch {
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Applies to both hover and return transitions */
}

.btn-launch:hover {
  transform: scale(1.05);
}

@media (min-width: 576px) {
  .assignment-container, .class-title-card, .image-container {
    width: 90% !important;
  }
}

@media (min-width: 768px) {
  .assignment-container, .class-title-card, .image-container {
    width: 75% !important;
  }
}

@media (min-width: 992px) {
  .assignment-container, .class-title-card, .image-container {
    width: 65% !important;
  }
}

@media (min-width: 1200px) {
  .assignment-container, .class-title-card, .image-container {
    width: 55% !important;
  }
}

.assignment-container, .class-title-card, .image-container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
}

@tailwind base;
@tailwind components;
@tailwind utilities;